@charset "utf-8";

:root {
  /* Brand colors */
  --xi-red: oklab(60.8% 0.171 0.082);
  --xi-gray: oklab(36.8% 0 0);

  /* Brand-derived colors */
  --xi-blue: oklab(60.8% -0.043 -0.135); /* split complement blue */

  /*
   * # Backgrounds and foregrounds
   */
  --background0: white; /* page background */
  --background: oklab(97.3% 0 0.001); /* background fade */
  --foreground: oklab(72% 0 -0.01);
  --foreground-alt: oklab(57% 0 0);
  --foreground-bright: oklab(37% 0 0);

  /* Measurements */
  --regular-line: 0.1rem;

  /* Fonts */
  --sans-serif: "Source Sans 3", sans-serif;
}

html {
  scroll-behavior: smooth;
  font-family: var(--sans-serif);
  color: var(--foreground);
}

body {
  margin: 0;
}

ul {
  list-style-type: square;

  li::marker {
    color: var(--xi-red);
  }
}

a {
  color: var(--xi-blue);
}

.btn {
  display: inline-block;
  border: var(--regular-line) solid var(--xi-red);
  color: var(--xi-red);
  text-transform: uppercase;
  text-decoration: none;
  background-color: transparent;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  padding: 0.5rem 1rem;
  margin: 0.5rem;
}

.menu-bar {
  position: sticky;
  top: 0;
  background-color: var(--background0);
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  gap: 2rem;
  z-index: 200;
  box-shadow: 0 0 0.5rem var(--xi-gray);

  a:not(.btn) {
    color: var(--foreground-alt);
    text-decoration: none;
  }

  .menu {
    flex: 1;

    button {
      display: none;
    }
  }

  .menu-items {
    display: flex;
    list-style: none;
    gap: 1rem;
    padding: 0;
    margin: 0;
    align-items: center;

    .fill {
      flex: 1 1 0;
    }
  }
}

/* On narrow screens, collapse the navbar into a menu */
@media only screen and (max-width: 35rem) {
  .menu-bar .menu {
    button {
      display: block;
      float: right;
    }

    .menu-items {
      visibility: hidden;
      flex-direction: column;
      background-color: var(--background0);
      position: fixed;
      top: 4rem;
      right: 0;
      padding: 1rem;
      padding-bottom: 1.5rem;
    }

    &:focus-within > .menu-items {
      visibility: visible;
    }
  }
}

header {
  text-align: center;
  background-color: var(--xi-red);
  padding: 2rem;
  color: var(--background0);
  font-weight: 100;
  font-size: 1.5rem;
  background: url("./images/vault-bg.jpg") center/cover no-repeat;
  line-height: 1.5;

  .product-name {
    font-weight: 300;
    font-size: 2rem;
  }

  .btn {
    border-color: var(--background);
    color: var(--background);
  }
}

h2 {
  font-weight: 100;
  color: var(--foreground-bright);
  font-size: 3rem;
  padding-bottom: 2rem;
}

.logo-bg {
  background: url("./images/logo-large.png") center/contain no-repeat;
  min-height: 6rem;
}

.color-logo-bg {
  min-height: 3rem;
  min-width: 8rem;
  background: url("./images/logo-full-color.png") center/contain no-repeat;
}

.catalog-bg {
  background: url("./images/catalog-sc.png") center/contain no-repeat;
  min-height: 20rem;
}

.zero-day-bg {
  background: url("./images/0-day-sc.png") center/contain no-repeat;
  min-height: 20rem;
}

.n-day-bg {
  background: url("./images/n-day-sc.png") center/contain no-repeat;
  min-height: 20rem;
}

header {
  h1 {
    margin: 1rem auto;
  }

  img {
    width: 100%;
    max-width: 30rem;
  }

  p {
    margin: 1em auto;
    max-width: 50rem;
  }
}

section {
  padding: 4rem 2rem 6rem;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  gap: 1rem;
  background: linear-gradient(to bottom, var(--background0), var(--background));
  position: relative;

  /* Chevron cutout overlapping previous section */
  &::before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    top: -2rem;
    left: 0;
    border-top: 2rem solid transparent;
    border-left: 50vw solid var(--background0);
    border-right: 50vw solid var(--background0);
  }

  &:nth-child(odd) {
    flex-direction: row-reverse;
  }

  h2,
  .action {
    width: 100%;
    text-align: center;
  }

  .screen,
  .description {
    flex: 1 1 25rem;
    max-width: 40rem;
  }

  .screen img {
    width: 100%;
  }
}

footer {
  margin: 0;
  background-color: var(--background);
  text-align: center;
  padding: 1rem;
}
